import cookieStore from '@paybis/frontend-common-lib/src/services/cookie';
import { captureException } from '@paybis/frontend-common-lib/src/plugins/sentry';
import { emitMobAppEvent } from '@paybis/frontend-common-lib/src/services/web-view-service';

const NSURE_DEVICE_ID_KEY = 'nsure-device-id';
const COOKIE_EXPIRATION = new Date().setFullYear(new Date().getFullYear() + 1); // expires in 1 year

const MAX_TICKS = 60;

let nSureDeviceId = null;
let nSureTimeoutId = null;
let nSureTicks = 0;
let nSureFailed = false;

const waitForNSureDeviceId = resolve => {
  if (nSureTimeoutId !== null) {
    clearTimeout(nSureTimeoutId);
  }

  if (nSureTicks > MAX_TICKS) {
    resolve();
    return;
  }

  if (nSureDeviceId === null) {
    nSureTicks += 1;
    nSureTimeoutId = setTimeout(() => waitForNSureDeviceId(resolve), 250);
    return;
  }

  resolve();
};

function addDeviceId(deviceId) {
  document
    .querySelectorAll('input[name="nsure-device-id"]')
    .forEach((element) => {
      element.value = deviceId || '';
    });
}

function storeDeviceId(deviceId) {
  nSureDeviceId = deviceId;
  cookieStore.set(NSURE_DEVICE_ID_KEY, deviceId, {
    expires: new Date(COOKIE_EXPIRATION),
  });
}

export const getDeviceId = async () => {
  if (nSureDeviceId) {
    return nSureDeviceId;
  }

  const cookieDeviceId = cookieStore.get(NSURE_DEVICE_ID_KEY) || '';

  if (nSureFailed) {
    captureException({
      error: new Error('nSure script failed to load'),
      extra: {
        deviceId: cookieDeviceId,
      },
    });

    return cookieDeviceId;
  }

  const isDeviceIdInitialized = new Promise(resolve => {
    waitForNSureDeviceId(resolve);
  });

  await isDeviceIdInitialized;

  const deviceId = typeof window !== 'undefined' && window.nSureSDK && window.nSureSDK.getDeviceId();
  const storedDeviceId = nSureDeviceId || deviceId;

  if (typeof storedDeviceId === 'string') {
    // XXX: we can return the stored device ID, since it doesn't change for the same device
    return storedDeviceId;
  }
  
  return cookieDeviceId;
};

// XXX: run this only, if we are inside
if (typeof window !== 'undefined') {
  window.nSureAsyncInit = (deviceId) => {
    // eslint-disable-next-line no-undef
    window.nSureSDK.init(NSURE_APP_ID);
    
    if ('' === deviceId) {
      throw new Error('No deviceId found from nSure');
    }
    
    storeDeviceId(deviceId);
    addDeviceId(deviceId);

    emitMobAppEvent('nsure-device-id', { deviceId });
  };
  
  const scriptElement = document.createElement('script');

  // eslint-disable-next-line no-undef
  scriptElement.src = NSURE_SCRIPT_URL;
  scriptElement.type = 'text/javascript';
  scriptElement.defer = 'defer';

  scriptElement.addEventListener('error', () => {
    nSureFailed = true;
  });

  const documentHead = document.getElementsByTagName('head').item(0);
  
  if (documentHead !== null) {
    documentHead.appendChild(scriptElement);
  }

  window.addEventListener('DOMContentLoaded', async () => {
    const deviceId = await getDeviceId();
    addDeviceId(deviceId);
  });
}
